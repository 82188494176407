import React, { useEffect, useMemo } from 'react';
import type { Product } from '@wilm/shared-types/product/Product';
import Skeleton from 'react-loading-skeleton';
import Input from 'components/commercetools-ui/atoms/input';
import { sdk } from 'sdk';
import type { SearchParams } from 'types/next';
import TestProductTile from '../../../test-plp-tile';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Button from 'components/commercetools-ui/atoms/button';
import { useCart } from 'frontastic';
import { useSession } from 'providers/session';
import cookie from 'js-cookie';
import type { SDKResponse } from '@commercetools/frontend-sdk';

interface Props {
    searchParams: SearchParams;
}

const TestPlp: React.FC<Props> = ({ searchParams }) => {
    const [term, setTerm] = React.useState<string>((searchParams?.q as string) || '');
    const [sku, setSku] = React.useState<string>((searchParams?.sku as string) || '');
    const [loading, setLoading] = React.useState<boolean>(false);

    const [selectedCurrency, setSelectedCurrency] = React.useState<string>('');
    const [products, setProducts] = React.useState<Product[]>([]);

    const { data } = useCart();
    const getProductsByName = async (query: string) => {
        const ctQuery = {
            query: query,
            currency: selectedCurrency,
            limit: 200
        };

        const productResults = (await sdk.composableCommerce.product.query(ctQuery)) as SDKResponse<{
            items: Product[];
            total: number;
        }>;

        return productResults;
    };

    const getProductBySku = async (sku: string) => {
        const productResults = await sdk.composableCommerce.product.getProduct({ id: sku, sku: sku });
        return productResults;
    };

    const handleSearch = () => {
        setLoading(true);
        if (sku) {
            void getProductBySku(sku).then(result => {
                if (!result.isError) {
                    const products: Product[] = [];
                    if (result.data.productId) products.push(result.data as Product);

                    setProducts(products);
                } else {
                    console.error('Error fetching products', result.error);
                }
                setLoading(false);
            });
        } else {
            void getProductsByName(term).then(result => {
                if (!result.isError) {
                    const products = result.data.items;

                    setProducts(products);
                } else {
                    console.error('Error fetching products', result.error);
                }
                setLoading(false);
            });
        }
    };

    const currenciesItems = useMemo(() => {
        return [
            { label: 'USD', value: 'USD' },
            { label: 'EUR', value: 'EUR' },
            { label: 'GBP', value: 'GBP' },
            { label: 'SGD', value: 'SGD' },
            { label: 'HKD', value: 'HKD' },
            { label: 'MYR', value: 'MYR' }
        ];
    }, []);

    const sessionSettings = useSession();

    useEffect(() => {
        setSelectedCurrency(data?.sum?.currencyCode ?? 'GBP');
    }, [data?.sum?.currencyCode]);

    const changeCurrency = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(selectedCurrency);
        const umbracoSessionCookie = await sdk.callAction({
            actionName: 'cart/createCartWithCurrency',
            payload: { currency: selectedCurrency }
        });
        console.log('---> umbracoSessionCookie', umbracoSessionCookie);
        console.log('---> sessionSettings', sessionSettings);

        if (umbracoSessionCookie.isError || !umbracoSessionCookie.data) {
            console.error('Error creating cart with currency');
            return;
        }

        cookie.set(sessionSettings.sessionCookieName!, umbracoSessionCookie.data as string, {
            domain: sessionSettings.sessionCookieDomain!
        });

        window.location.reload();
    };

    return (
        <div>
            <form autoComplete="off" onSubmit={changeCurrency}>
                <div className="flex flex-col gap-25 py-16 pb-32 text-base leading-6 md:gap-20 md:p-40">
                    <p className="font-bold">Cart currency is {selectedCurrency}. If you need to change it, use the dropdown below.</p>
                    <Dropdown
                        items={currenciesItems}
                        label="Currency"
                        onChange={e => {
                            setSelectedCurrency(e.target.value);
                        }}
                        value={selectedCurrency}
                        required={true}
                        name="currency"
                        id="currency"
                    />
                    <div className="flex justify-end gap-12">
                        <Button variant="primary" type="submit" className="w-full rounded-md md:rounded-lg">
                            Change currency *
                        </Button>
                    </div>
                    <p className="font-bold text-red-500">
                        * Clicking &quot;Change currency&quot; button will create a new cart with selected currency and set it to
                        umbraco-session cookie. Then it will refresh the page. After the refresh you will be working with the new cart. You
                        will lose all of the information from the old cart.
                    </p>
                </div>
            </form>
            <div className="px-32 py-16">
                Search:
                <Input
                    value={term}
                    onChange={e => {
                        setSku('');
                        setTerm(e.target.value);
                    }}
                />
            </div>
            <div className="px-32 py-16">
                Search by sku:
                <Input
                    value={sku}
                    onChange={e => {
                        setTerm('');
                        setSku(e.target.value);
                    }}
                />
            </div>
            <div className="flex justify-end gap-12 px-32 py-16">
                <Button variant="primary" type="submit" className="w-full rounded-md md:rounded-lg" onClick={handleSearch}>
                    Search
                </Button>
            </div>
            <p className="px-32">
                Product results contains only products that has currency: <span className="font-bold">{selectedCurrency}</span>.
            </p>
            <div className="grid grid-cols-2 gap-16 px-16 pt-32 md:grid-cols-3 lg:grid-cols-4 lg:gap-24">
                {loading && (
                    <>
                        <Skeleton className="h-200 w-full" />
                        <Skeleton className="h-200 w-full" />
                        <Skeleton className="h-200 w-full" />
                        <Skeleton className="h-200 w-full" />
                    </>
                )}
                {!loading && products.map(product => <TestProductTile key={product.productId} product={product} />)}
                {products.length === 0 && !loading && <div className="px-16 text-xl font-bold">No products found</div>}
            </div>
        </div>
    );
};

export default TestPlp;
