'use client';

import React from 'react';
import type { Product } from '@wilm/shared-types/product/Product';
import TestPlp from 'components/commercetools-ui/organisms/product/product-list/components/test-plp';
import type { DataSource } from 'types/datasource';
import type { TasticProps } from 'frontastic/tastics/types';

interface DataSourceProps {
    items: Product[];
    category: string;
    previousCursor?: string;
    nextCursor?: string;
    total: number;
    totalItems: number;
}

const ProductListWrapped = ({ searchParams }: TasticProps<DataSource<DataSourceProps>>) => {
    return <TestPlp searchParams={searchParams} />;
};

const ProductListTastic = ({ ...props }: TasticProps<DataSource<DataSourceProps>>) => {
    return <ProductListWrapped {...props} />;
};
export default ProductListTastic;
